/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import './layout.scss';

interface LayoutProps {
  sections: any[],
}

const Layout: React.FC<LayoutProps> = ({ sections }: LayoutProps) => (
  <div className="layout">
    { sections.map((section, i) => (
      <div
        className={`section${sections.length - 1 === i ? ' --final' : ''}${
          i === sections.length - 2 ? ' bp-m-only' : ''
        }`}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
      >
        {section}
      </div>
    )) }
  </div>
);

export default Layout;
